import _ from "lodash";

/**
 * Used in `getInitialProps` to abort rendering a page and instead respond with
 * the error page and a specific status code.
 *
 *     throw new HttpError({
 *       status: 404,
 *       message: `Unknown product id: ${id}`,
 *     })
 */
export class HttpError extends Error {
  constructor({ status, message }) {
    super(`HttpError: ${status}\n${message}`);
    this.status = status;
  }
}

/**
 * Adds `queryString` at the end of `url` (which might already have a query
 * string), taking care of the question mark.
 */
export function addQueryString(url, queryString) {
  const strippedUrl = url.replace(/\?$/, "");
  const separator = strippedUrl.includes("?") ? "&" : "?";
  return queryString === "" ? url : `${strippedUrl}${separator}${queryString}`;
}

/**
 * Returns a template tag that escapes all interpolations using `fn`. Example
 * usage:
 *
 *     escape(encodeURIComponent)`/users/${user}`
 */
export function escape(fn) {
  // There’s always one more literal than interpolations.
  return (literals, ...interpolations) =>
    interpolations
      .map((interpolation, index) => `${literals[index]}${fn(interpolation)}`)
      .concat(literals[literals.length - 1])
      .join("");
}

/**
 * Copied from:
 * https://github.com/sindresorhus/escape-goat/blob/deaa41d2e6a2b6345ec23fa9e5b8ff1706693c9b/index.js#L3-L8
 *
 * Copied since that library uses ES6 syntax, which doesn’t work in IE11.
 */
export function escapeHtml(string) {
  return string
    .replace(/&/g, "&amp;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#39;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
}

export function makeShowroomOptions(showrooms) {
  const cities = Array.from(new Set(showrooms.map(showroom => showroom.city)));
  return _.uniqBy(
    cities.map(city => ({
      key: city.toLowerCase(),
      text: city,
    })),
    ({ key }) => key,
  );
}

/**
 * Turns an object with string, undefined or array-of-string values to a URL
 * query string.
 */
export function makeQueryString(data) {
  return []
    .concat(
      ...Object.entries(data).map(([key, value]) =>
        value == null
          ? []
          : Array.isArray(value)
          ? value.map(subValue => [key, subValue])
          : [[key, value]],
      ),
    )
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`,
    )
    .join("&");
}

/**
 * Because `noop` is nicer to read than `() => {}`.
 */
export function noop() {
  // Do nothing.
}

/**
 * Returns `[fn(0), fn(1), fn(2), ..., fn(n - 1)]`.
 */
export function range(n, fn) {
  return Array.from({ length: n }, (__, i) => fn(i));
}

/**
 * Turns a `Date` instance into "YYYY-MM-DD".
 */
export function toISODateString(date) {
  return date.toISOString().split("T")[0];
}

/**
 * A template tag that contructs URLs by escaping all interpolations using
 * `encodeURIComponent`. Example usage:
 *
 *     url`/users/${user}`
 */
export const url = escape(encodeURIComponent);
