import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Collapse } from "react-collapse";

import EventListener from "../../EventListener";
import Link from "../../Link";
import styles from "./styles.scss";

NavDropDown.propTypes = {
  liProps: PropTypes.object,
  menu: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          link: PropTypes.shape({
            route: PropTypes.string,
            href: PropTypes.string,
            // ...routeParams (passed to <Link>)
          }).isRequired,
        }).isRequired,
      ).isRequired,
    ]).isRequired,
  ).isRequired,
  burger: PropTypes.bool,
  children: PropTypes.node,
};

NavDropDown.defaultProps = {
  burger: false,
  children: null,
  liProps: {},
};

function NavDropDown({ burger, menu, children, liProps }) {
  const [open, setOpen] = React.useState(false);
  const [overlap, setOverlap] = React.useState(undefined);
  const wrapperRef = React.useRef();
  const linkRef = React.useRef();

  // prevent the dropdown rendering outside the body, breaking the layout
  React.useEffect(() => {
    if (!burger && wrapperRef.current && overlap === undefined) {
      const rect = wrapperRef.current.getBoundingClientRect();
      const o = window.innerWidth - (rect.x + rect.width);
      setOverlap(o < 1 ? o : 0);
    }
  }, [burger, open, overlap]);

  const handleClickOutside = event => {
    if (
      event.target instanceof Node &&
      linkRef.current &&
      linkRef.current.contains(event.target)
    ) {
      return;
    }
    if (
      event.target instanceof Node &&
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target)
    ) {
      setOpen(false);
    }
  };

  const eventListeners = (
    <>
      <EventListener eventName="focus" listener={handleClickOutside} />
      <EventListener eventName="click" listener={handleClickOutside} />
      <EventListener
        eventName="keydown"
        listener={event => {
          if (event.key === "Escape") {
            setOpen(false);
          }
        }}
      />
    </>
  );

  const menuContent = (
    <>
      {!burger && eventListeners}
      <div
        ref={wrapperRef}
        style={{
          transform: `translateX(${overlap || 0}px)`,
          opacity: overlap === undefined && !burger ? 0 : 1,
        }}
        className={classnames(
          burger ? styles["DropDown--side"] : styles.DropDown,
        )}
      >
        {menu.map((item, index) =>
          Array.isArray(item) ? (
            <ul key={index}>
              {item.map(listItem => {
                return (
                  <li key={listItem.name}>
                    {listItem.link.route != null && (
                      <Link {...listItem.link}>
                        <a>{listItem.name}</a>
                      </Link>
                    )}
                    {listItem.link.href != null && (
                      <a
                        {...isExternal(listItem.link.href)}
                        href={listItem.link.href}
                      >
                        {listItem.name}
                      </a>
                    )}
                  </li>
                );
              })}
            </ul>
          ) : (
            <h3 key={index}>{item}</h3>
          ),
        )}
      </div>
    </>
  );

  return (
    <ul
      {...liProps}
      ref={linkRef}
      onClick={() => setOpen(v => !v)}
      className={classnames(liProps.className, {
        [styles.DropDownToggledLink]: open,
      })}
    >
      {children}
      {burger ? (
        <Collapse isOpened={open}>{menuContent}</Collapse>
      ) : (
        open && menuContent
      )}
    </ul>
  );
}

function isExternal(link) {
  return link.startsWith("http:")
    ? { rel: "noopener noreferrer", target: "_blank" }
    : {};
}

export default NavDropDown;
