import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import * as api from "../../api";
import logoDark from "../../images/logo-dark.svg";
import logoLight from "../../images/logo-light.svg";
import Burger from "../Burger";
import EventListener from "../EventListener";
import Link, { linkPropType } from "../Link";
import NavDropDown from "./NavDropDown";
import styles from "./styles.scss";

const ITEMS = [
  {
    name: "Offert direkt",
    link: { route: "chat" },
  },
];

Nav.propTypes = {
  transparent: PropTypes.bool,
  fixed: PropTypes.bool,
  external: PropTypes.oneOfType(PropTypes.bool, PropTypes.string),
};

Nav.defaultProps = {
  transparent: true,
  fixed: false,
  external: false,
};

function Nav({ transparent, fixed, external }) {
  const sideNavRef = React.useRef();

  const [sideNavOpen, setSideNavOpen] = React.useState(false);
  const [currentlyOpenDropDown, setDropDown] = React.useState("");
  const [nav, setNav] = React.useState(ITEMS);

  React.useEffect(() => {
    const buildSubItem = subItem => {
      return subItem.links
        ? subItem.label
          ? [subItem.label, ...subItem.links.map(buildSubItem)]
          : subItem.links.map(buildSubItem)
        : [
            {
              name: subItem.label,
              link: { href: subItem.page.url },
            },
          ];
    };
    const buildItem = item => {
      return item.sublinks
        ? {
            name: item.label,
            menu: item.sublinks.map(buildSubItem).flat(),
          }
        : {
            name: item.label,
            link: { href: item.page },
          };
    };
    (async () => {
      if (external) {
        try {
          const navItems = await api.getExternalNav();
          if (navItems) {
            setNav(navItems.menu_links.map(buildItem));
          }
        } catch (e) {
          console.error("Failed to fetch navbar", e);
        }
      }
    })();
  }, [external]);

  function closeSideNavIfOutside(event) {
    if (
      sideNavRef.current != null &&
      event.target instanceof Node &&
      !sideNavRef.current.contains(event.target)
    ) {
      setSideNavOpen(false);
    }
  }

  return (
    <>
      <div
        className={classnames(
          transparent ? styles.transparent : styles.regular,
          {
            [styles.fixed]: fixed,
          },
        )}
      >
        <div className={styles.inner}>
          {!external ? (
            <Link route="home">
              <a className={styles.logoLink}>
                <img
                  src={transparent ? logoLight : logoDark}
                  alt="doowin"
                  className={styles.logo}
                />
              </a>
            </Link>
          ) : (
            <a className={styles.logoLink} href={external}>
              <img
                src={transparent ? logoLight : logoDark}
                alt="doowin"
                className={styles.logo}
              />
            </a>
          )}
          <nav className={styles.right}>
            <ul className={styles.list}>
              {nav.map(item =>
                item.menu ? (
                  <NavDropDown
                    liProps={{ className: styles.itemWrapper }}
                    key={item.name}
                    {...item}
                    shifted={item.shifted}
                  >
                    <button type="button" className={styles.link}>
                      {item.name}
                    </button>
                  </NavDropDown>
                ) : (
                  <li key={item.name} className={styles.itemWrapper}>
                    <MaybeLink link={item.link}>
                      <a className={styles.link}>{item.name}</a>
                    </MaybeLink>
                  </li>
                ),
              )}

              <li
                className={classnames(styles.itemWrapper, styles.burgerWrapper)}
              >
                <Burger
                  active={sideNavOpen}
                  className={styles.hamburgerButton}
                  onClick={() => {
                    setSideNavOpen(v => !v);
                  }}
                >
                  Meny
                </Burger>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      {nav.length > 0 && (
        <div
          className={classnames(styles.sideNav, {
            [styles.sideNavOpen]: sideNavOpen,
          })}
          ref={sideNavRef}
        >
          <div className={styles.header}>
            <Burger
              active
              onClick={() => {
                setSideNavOpen(false);
              }}
            />
          </div>
          {sideNavOpen && (
            <>
              <EventListener
                eventName="focus"
                listener={closeSideNavIfOutside}
              />

              <EventListener
                eventName="click"
                listener={closeSideNavIfOutside}
              />

              <EventListener
                eventName="keydown"
                listener={event => {
                  if (event.key === "Escape") {
                    setSideNavOpen("");
                  }
                }}
              />
            </>
          )}

          <ul className={styles.scroller}>
            {nav.map(item => (
              <li key={item.name}>
                {item.menu ? (
                  <NavDropDown
                    close={() => setDropDown("")}
                    {...item}
                    show={currentlyOpenDropDown === item.name}
                    burger
                  >
                    <a
                      className={classnames(styles.sidenavLink, {
                        [styles.small]: item.small,
                      })}
                    >
                      {item.name}
                    </a>
                  </NavDropDown>
                ) : (
                  <MaybeLink link={item.link}>
                    <a
                      className={classnames(styles.sidenavLink, {
                        [styles.small]: item.small,
                      })}
                    >
                      {item.name}
                    </a>
                  </MaybeLink>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}

function MaybeLink({ link, children }) {
  return typeof link === "string" ? (
    React.cloneElement(children, { href: link })
  ) : (
    <Link {...link}>{children}</Link>
  );
}

MaybeLink.propTypes = {
  link: linkPropType.isRequired,
  children: PropTypes.node.isRequired,
};

export default Nav;
