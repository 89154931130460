import PropTypes from "prop-types";
import React from "react";

import styles from "./styles.scss";

/**
 * This components styles its children as a document: Space between paragraphs,
 * lists have bullets, links looks like links, etc.
 */
export default class Text extends React.Component {
  static propTypes = {
    // Supports either a string of HTML or React children.
    html: PropTypes.string,
    children: PropTypes.node,
    executeScripts: PropTypes.bool,
  };

  static defaultProps = {
    html: undefined,
    children: null,
    executeScripts: false,
  };

  rootRef = React.createRef();

  componentDidMount() {
    this.runScripts();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.html !== prevProps.html ||
      (this.props.executeScripts && !prevProps.executeScripts)
      // Comparing `children` is not worth the trouble.
    ) {
      this.runScripts();
    }
  }

  runScripts() {
    const { executeScripts } = this.props;
    const root = this.rootRef.current;

    if (root == null || !executeScripts) {
      return;
    }

    // `<script>`s inserted using `.innerHTML` are not executed, but replacing
    // them with new nodes do execute them.
    // https://developer.mozilla.org/en-US/docs/Web/API/Element/innerHTML#Security_considerations
    Array.from(root.querySelectorAll("script")).forEach(script => {
      const newScript = document.createElement("script");
      Array.from(script.attributes).forEach(attr => {
        newScript.setAttribute(attr.name, attr.value);
      });
      newScript.textContent = script.textContent;
      script.parentNode.replaceChild(newScript, script);
    });
  }

  render() {
    const { html, children } = this.props;

    if (html != null && children != null) {
      throw new Error("<Text>: Provide either `html` OR `children`, not both.");
    }

    const commonProps = {
      className: styles.root,
      ref: this.rootRef,
    };

    if (html != null) {
      return (
        <div
          {...commonProps}
          dangerouslySetInnerHTML={html == null ? undefined : { __html: html }}
        />
      );
    }

    return <div {...commonProps}>{children}</div>;
  }
}
