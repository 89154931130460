import getConfig from "next/config";
import Head from "next/head";
import PropTypes from "prop-types";
import React from "react";

import Nav from "../Nav";

const TITLE_BASE = "doowin";

const config = getConfig();
const EXTERNAL_NAV =
  config != null
    ? config.serverRuntimeConfig.EXTERNAL_NAV ||
      config.publicRuntimeConfig.EXTERNAL_NAV
    : false;

Layout.propTypes = {
  transparentNav: PropTypes.bool,
  fixedNav: PropTypes.bool,
  title: PropTypes.string,
  canonical: PropTypes.string,
  noindex: PropTypes.bool,
  skipTitle: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
  transparentNav: true,
  fixedNav: false,
  title: undefined,
  canonical: undefined,
  skipTitle: false,
  noindex: false,
};

export default function Layout({
  transparentNav,
  fixedNav,
  skipTitle,
  title,
  canonical,
  noindex,
  children,
}) {
  const fullTitle = title == null ? TITLE_BASE : `${title} – ${TITLE_BASE}`;
  return (
    <div>
      <Head>
        {skipTitle === false && <title>{fullTitle}</title>}
        {canonical && <link rel="canonical" href={canonical} />}
        {noindex && <meta name="robots" content="noindex" />}
      </Head>

      <Nav
        external={EXTERNAL_NAV}
        transparent={transparentNav}
        fixed={fixedNav}
      />

      <main>{children}</main>
    </div>
  );
}
