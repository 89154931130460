import { makeUrls } from "next-minimal-routes";
import NextLink from "next/link";
import PropTypes from "prop-types";
import React from "react";

import { ROUTES } from "../../routes";

export const linkPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    route: PropTypes.oneOf(Object.keys(ROUTES)),
    params: PropTypes.object,
    query: PropTypes.object,
    hash: PropTypes.string,
  }).isRequired,
]);

Link.propTypes = {
  route: PropTypes.oneOf(Object.keys(ROUTES)),
  params: PropTypes.object,
  query: PropTypes.object,
  hash: PropTypes.string,
  children: PropTypes.element.isRequired,
};

Link.defaultProps = {
  route: null,
  params: {},
  query: {},
  hash: "",
};

// Wrapper around the Next.js Link that is is aware of routes.
// Note: In a new project I’d default `passHref` to `true` here and not bake
// `<Link>` into `<Button>` and other components, instead using
// `<Link><Button>Text</Button></Link>`.
export default function Link({
  route: routeName,
  params,
  query,
  hash,
  children,
  ...restProps
}) {
  const route = ROUTES[routeName];
  let external = false;

  if (route == null) {
    external = true;
  }

  const child = React.Children.only(children);

  const routeProps = !external
    ? makeUrls({
        route,
        params,
        query,
        hash,
      })
    : {};

  return (
    <NextLink {...routeProps} {...restProps}>
      {child}
    </NextLink>
  );
}
