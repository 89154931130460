import unfetch from "isomorphic-unfetch";
import cookies from "js-cookie";
import getConfig from "next/config";

import { addQueryString, makeQueryString } from "../utils";

const config = getConfig();

export const API_URL =
  // `config` is undefined in Storybook.
  config == null
    ? ""
    : config.serverRuntimeConfig.API_URL || config.publicRuntimeConfig.API_URL;
export const NAV_API_URL =
  config == null
    ? ""
    : config.serverRuntimeConfig.NAV_API_URL ||
      config.publicRuntimeConfig.NAV_API_URL;

const JSON_REGEX = /\bapplication\/json\b/;

/**
 * Makes a request to `apiUrl` using `method` and sending `data`. Returns a
 * `Promise` that resolves to the returned JSON. The promise is rejected for
 * error status codes and network errors.
 *
 * Note: The backend is trusted to consistenly return a correct JSON value for
 * every endpoint. No validation is done.
 *
 * Example usage:
 *
 *     request("GET", "/api/something", {foo: bar}).then(
 *       result => {
 *         // Success: Use `result`.
 *       },
 *       error => {
 *         // Handle unexpected error (network error, server error (500),
 *         // JSON parse error, etc.)
 *         // error.status: number
 *         // error.responseText: string
 *       },
 *     );
 */
export default async function request(
  method,
  apiUrl,
  data = undefined,
  { silencedStatusCodes = [] } = {},
) {
  const csrftoken = cookies.get("csrftoken");
  return requestBase(method, `${API_URL}${apiUrl}`, data, {
    silencedStatusCodes,
    headers: {
      Accept: "application/json",
      "Content-Type": data == null ? "" : "application/json",
      "X-CSRFToken": csrftoken == null ? "" : csrftoken,
    },
  });
}

export async function requestBase(
  method,
  fullUrl,
  data = undefined,
  { silencedStatusCodes = [], headers = { Accept: "application/json" } } = {},
) {
  const isGET = method.toLowerCase() === "get";

  const url =
    data != null && isGET
      ? addQueryString(fullUrl, makeQueryString(data))
      : fullUrl;

  try {
    const response = await unfetch(url, {
      method,
      credentials: "include",
      body: data != null && !isGET ? JSON.stringify(data) : undefined,
      headers,
    });

    const isJSON = JSON_REGEX.test(response.headers.get("Content-Type"));
    const responseData = await (isJSON ? response.json() : response.text());

    // For debugging.
    response.__input = data;
    response.__output = responseData;

    if (response.status >= 200 && response.status < 400) {
      return responseData;
    }

    const error = new Error(`Non-success status code: ${response.status}`);
    error.response = response;
    error.responseData = responseData;
    throw error;
  } catch (error) {
    const { response } = error;

    error.message = `${
      response == null
        ? "(no response)"
        : `${response.status} ${response.statusText}`
    } ${method} ${url}:\n${error.message}`;

    error.status = response == null ? -1 : response.status;

    // Using `defineProperty` rather than `error.responseText = ...` to avoid
    // `.responseText` showing up in Node.js error logs (see also
    // `patchResponse`).
    Object.defineProperty(error, "responseText", {
      value: response == null ? "" : stringify(response.__output),
    });

    if (!silencedStatusCodes.includes(error.status)) {
      console.error("REST API error", error);
    }

    throw error;
  }
}

function stringify(object) {
  if (typeof object === "string") {
    return object;
  }
  // `JSON.stringify(undefined) === undefined`
  if (object === undefined) {
    return "undefined";
  }
  try {
    return JSON.stringify(object, undefined, 2);
  } catch (error) {
    return `(JSON.stringify failed for \`${object}\`: ${error.message})`;
  }
}

// Patch the `Response` objects from "node-fetch" (used by "isomorphic-unfetch")
// to make the Node.js error logs easier to read.
export function patchResponse() {
  if (typeof window === "undefined") {
    // eslint-disable-next-line import/no-extraneous-dependencies, no-undef
    const { Response } = require("node-fetch");
    const inspect = Symbol.for("nodejs.util.inspect.custom");
    Response.prototype[inspect] = function() {
      const { __input, __output, ...rest } = this;
      const input = stringify(__input);
      const output =
        typeof __output === "string"
          ? // Try to remove excessive __output from Django error messages.
            DEBUG
            ? __output.replace(
                /\n(Python Path|Installed (Applications|Middleware)):\s*\[[^\]]*\]|\n(META|Settings):(\nUsing.*)?(\n\S+ =.+)*/g,
                "",
              )
            : __output
          : stringify(__output);
      const indent = (string, i) => string.replace(/\n/g, `\n${" ".repeat(i)}`);
      return {
        ...rest,
        __io: {
          [inspect]: (depth, { indentationLvl: i }) =>
            indent(
              `INPUT:\n ${indent(input, 1)}\nOUTPUT:\n ${indent(output, 1)}`,
              i,
            ),
        },
      };
    };
  }
}
