import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import styles from "./styles.scss";

Burger.propTypes = {
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

Burger.defaultProps = {
  active: false,
  className: "",
  children: undefined,
};

export default function Burger({ onClick, active, className, children }) {
  return (
    <button
      type="button"
      onClick={() => {
        onClick();
      }}
      className={classnames(styles.root, className, {
        [styles.active]: active,
      })}
    >
      {children != null && <div className={styles.label}>{children}</div>}

      <div>
        <span className={styles.top} />
        <span className={styles.middle} />
        <span className={styles.bottom} />
      </div>
    </button>
  );
}
