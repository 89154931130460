import { url } from "../utils";
import request, { NAV_API_URL, requestBase } from "./request";

export function createChatMessage(data) {
  return request("POST", url`/v1.0/chat/`, data);
}

export function getChatMessages({ id }, data) {
  return request("GET", url`/v1.0/chat/${id}/`, data);
}

export function getChatSummary({ id }) {
  return request("GET", url`/v1.0/chat/${id}/summary/`);
}

export function getQuote(id) {
  return request("GET", url`/v1.0/quote/${id}/`);
}

export function getQuoteSnippet(id) {
  return request("GET", url`/v1.0/quote/${id}/snippet/`);
}

export function acceptQuote(id, data) {
  return request("POST", url`/v1.0/quote/${id}/accept/`, data);
}

export function getShowrooms() {
  return request("GET", url`/v1.0/showroom/`);
}

export function contactMe(data) {
  return request("POST", url`/v1.0/customer/contact_me/`, data);
}

export function becomeFranchise(data) {
  return request("POST", url`/become_franchise/`, data);
}

export function getExternalNav() {
  return requestBase("GET", NAV_API_URL);
}
