/* global module, require */

const { makeRoute } = require("next-minimal-routes");

// A `.sitemap` function that results in zero sitemap URLs.
const excludeFromSitemap = () => [];

const ROUTES = {
  home: makeRoute({
    page: "/chat",
    pattern: "/",
  }),

  chat: makeRoute({
    page: "/chat",
    pattern: "/chat",
  }),

  quote: makeRoute({
    page: "/quote",
    pattern: "/offert",
  }),

  quoteCancelled: makeRoute({
    page: "/quote/cancel",
    pattern: "/offert/avbryt",
    sitemap: excludeFromSitemap,
  }),

  quoteDone: makeRoute({
    page: "/quote/done",
    pattern: "/offert/klar",
    sitemap: excludeFromSitemap,
  }),
};

module.exports = {
  ROUTES,
};
